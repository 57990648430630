<template>
  <div>
    <TTableSimple
      :fields="itemFields"
      :items="items"
      @update:create="onInputItem"
      resource=""
    >
      <template #_-header>
        <TButton
          icon="cil-plus"
          size="sm"
          tooltip="Add lading bill"
          variant="ghost"
          @click="showModalSelectOrder = true"
        />
      </template>
      <template #_="{ item }">
        <td>
          <TButtonRemove @click="removeItem(item)" />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TMessage :content="item.lading_bill.customer.name" noTranslate />
        </td>
      </template>
      <template #lading_bill="{ item }">
        <td>
          <TMessage
            :content="item.lading_bill.name || item.lading_bill.id"
            noTranslate
          />
        </td>
      </template>
      <template #freight_charge="{ item }">
        <td class="text-nowrap">
          <div class="d-flex">
            <TMessageMoney :amount="item.lading_bill.freight_charge_paid" />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.lading_bill.freight_charge"
              :currency="item.lading_bill.currency_id"
            />
          </div>
        </td>
      </template>
      <template #freight_charge_to_pay="{ item }">
        <td class="text-nowrap">
          <TMessageMoney
            editable
            @change="item.lading_bill.freight_charge_to_pay = $event"
            :amount="item.lading_bill.freight_charge_to_pay"
            :currency="currency_id"
          />
        </td>
      </template>
      <template #result-freight_charge_to_pay>
        <th>
          <div class="d-flex">
            <TMessage content="Total:" />
            <i>
              <TMessageMoney
                :amount="getTotal()"
                :currency="currency_id"
                class="ml-1"
                :color="getColorTotal()"
              />
            </i>
          </div>
        </th>
      </template>
    </TTableSimple>
    <ModalSelectLadingBills
      :show.sync="showModalSelectOrder"
      @select="addLadingBills"
      type="service_fee"
      :customer_id="customer_id"
    />
  </div>
</template>

<script>
import ModalSelectLadingBills from "./ModalSelectLadingBills.vue";

export default {
  components: {
    ModalSelectLadingBills,
  },
  props: {
    currency_id: {
      type: String,
      required: false,
    },
    total_amount: {
      type: Number,
      default: 0,
    },
    customer_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      showModalSelectOrder: false,
      items: [],
      itemInput: {},
      itemFields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
        },
        {
          key: "customer",
          label: "Customer",
        },
        {
          key: "lading_bill",
          label: "Lading bill",
        },
        {
          key: "freight_charge",
          label: "Paid/Shipping fee",
        },
        {
          key: "freight_charge_to_pay",
          label: "Shipping fee to pay",
          _style: "min-width: 350px; width: 350px",
        },
      ],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        this.$emit("update:items", items);
      },
    },
  },
  methods: {
    onInputItem(value) {
      this.itemInput = value;
    },
    removeItem(item) {
      const itemIdx = this.items.findIndex(
        (di) => di.lading_bill.id == item.lading_bill.id
      );
      this.items.splice(itemIdx, 1);
    },
    getTotal() {
      return this.lodash.sumBy(
        this.items.map((i) => i.lading_bill),
        "freight_charge_to_pay"
      );
    },
    getColorTotal() {
      return this.getColorAmount(this.getTotal(), this.total_amount);
    },
    getColorAmount(amount, amount_outstanding) {
      if (amount > amount_outstanding) {
        return "warning";
      }
      if (amount === amount_outstanding) {
        return "success";
      }
      return null;
    },
    addLadingBills(lbs) {
      const mapped_items = lbs.map((lb) => ({
        lading_bill: {
          ...lb,
          freight_charge_to_pay: lb.freight_charge_outstanding,
        },
        description: "",
      }));

      this.items = this.lodash.uniqBy(
        [...this.items, ...mapped_items],
        "lading_bill.id"
      );
    },
    clear() {
      this.items = [];
    },
  },
};
</script>
