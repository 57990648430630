<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage content="Debt payment customers" bold />
        <TSpinner :loading="creating" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{ disabled: creating }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol md="6">
              <SSelectCard
                label="Card"
                class="mb-3"
                :value.sync="input.card_id"
                @change="input.currency_id = $event.currency_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                placeholder="Ref code"
                class="mb-3"
                :value.sync="input.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol>
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol>
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :value.sync="input.currency_id"
                    :selectOptions="{ disabled: true }"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="12">
              <TInputMoney
                label="Total amount"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.amount"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
        <CCol>
          <SelectTypeDebtPayment :value.sync="type" />
          <TableInputServiceFee
            v-if="type === 'payment_service'"
            :currency_id="input.currency_id"
            ref="table_input_service_fee"
            :total_amount="input.amount"
            @update:items="items = $event"
            :customer_id="input.transactionable_id"
            :key="`table_input_service_fee-${input.transactionable_id}`"
          />
          <TableInputCostOfGoods
            v-if="type === 'payment_order'"
            ref="table_input_purchase_cost"
            :currency_id="input.currency_id"
            :total_amount="input.amount"
            @update:items="items = $event"
            :customer_id="input.transactionable_id"
            :key="`table_input_purchase_cost-${input.transactionable_id}`"
          />
          <TableInputLadingBills
            v-if="type === 'payment_service_lading_bill'"
            ref="table_input_lading_bills"
            :currency_id="input.currency_id"
            :total_amount="input.amount"
            @update:items="items = $event"
            :customer_id="input.transactionable_id"
            :key="`table_input_lading_bills-${input.transactionable_id}`"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import SelectTypeDebtPayment from "../../../components/SelectTypeDebtPayment.vue";
import TableInputServiceFee from "../../../components/TableInputServiceFee.vue";
import TableInputCostOfGoods from "../../../components/TableInputCostOfGoods.vue";
import TableInputLadingBills from "../../../components/TableInputLadingBills.vue";

export default {
  components: {
    SelectTypeDebtPayment,
    TableInputServiceFee,
    TableInputCostOfGoods,
    TableInputLadingBills,
  },
  data() {
    return {
      input: {},
      items: [],
      receipts: [],
      type: "payment_service",
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_receive_transfer.creating"
      ];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_receive_transfer.received_from_foreign_currency",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let item_customers = [];
      let item_type_key =
        this.type === "payment_service_lading_bill" ? "lading_bill" : "order";

      this.items.forEach((item) => {
        item_customers[item[item_type_key].customer.id] = [
          ...(item_customers[item[item_type_key].customer.id] ?? []),
          item,
        ];
      });

      let deposits = [];

      this.lodash.keys(item_customers).forEach((customer_id) => {
        const serializeDataDeposit = this.serializeDataDeposit(
          { transactionable_id: customer_id },
          item_customers[customer_id]
        );

        deposits.push({
          ...serializeDataDeposit,
          "[transactionable_type]": "user",
          "[transactionable_id]": customer_id,
        });
      });

      let params = [];

      deposits.forEach((deposit, index) => {
        let total_amount = 0;
        const serializeDeposit = this.lodash.mapKeys(deposit, (value, key) => {
          if (this.lodash.endsWith(key, "[amount]")) {
            total_amount += value;
          }
          return `deposit[${index}]${key}`;
        });
        serializeDeposit[`deposit[${index}][amount]`] = total_amount;
        params = { ...params, ...serializeDeposit };
      });

      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });

      return { ...this.input, ...params };
    },
    serializeDataDeposit(input, items) {
      let deposit = {
        ...input,
        transactionable_type: "user",
      };

      let payments = [];

      switch (this.type) {
        case "payment_service":
          items.forEach((item) => {
            // payment service fee
            if (item.order.service_fee_to_pay > 0) {
              payments.push({
                amount: item.order.service_fee_to_pay,
                receiptable_type: "order",
                receiptable_id: item.order.id,
              });
            }
            // payment shipping fee
            item.lading_bills.forEach((lading_bill) => {
              if (lading_bill.shipping_fee_to_pay > 0) {
                payments.push({
                  amount: lading_bill.shipping_fee_to_pay,
                  receiptable_type: "lading_bill",
                  receiptable_id: lading_bill.id,
                });
              }
            });
          });
          break;
        case "payment_order":
          items.forEach((item) => {
            // payment cost of goods
            if (item.order.purchase_cost_to_pay > 0) {
              payments.push({
                amount: item.order.purchase_cost_to_pay,
                receiptable_type: "order",
                receiptable_id: item.order.id,
              });
            }
          });
          break;

        case "payment_service_lading_bill":
          items.forEach((item) => {
            // payment service fee of lading bills
            if (item.lading_bill.freight_charge_to_pay > 0) {
              payments.push({
                amount: item.lading_bill.freight_charge_to_pay,
                receiptable_type: "lading_bill",
                receiptable_id: item.lading_bill.id,
              });
            }
          });
          break;
      }

      payments.forEach((payment, index) => {
        deposit[
          `[${
            this.type === "payment_service_lading_bill"
              ? "payment_service"
              : this.type
          }][${index}][amount]`
        ] = payment.amount;
        deposit[
          `[${
            this.type === "payment_service_lading_bill"
              ? "payment_service"
              : this.type
          }][${index}][receipts][0][receiptable_type]`
        ] = payment.receiptable_type;
        deposit[
          `[${
            this.type === "payment_service_lading_bill"
              ? "payment_service"
              : this.type
          }][${index}][receipts][0][receiptable_id]`
        ] = payment.receiptable_id;
      });

      return deposit;
    },
    clearInput() {
      this.input = {};
      this.receipts = [];
      this.$refs.table_input_service_fee?.clear();
      this.$refs.table_input_purchase_cost?.clear();
      this.$refs.table_input_lading_bills?.clear();
    },
  },
};
</script>
