<template>
  <div>
    <TTableSimple
      :fields="itemFields"
      :items="items"
      @update:create="onInputItem"
    >
      <template #_-header>
        <TButton
          icon="cil-plus"
          size="sm"
          tooltip="Add order"
          variant="ghost"
          @click="showModalSelectOrder = true"
        />
      </template>
      <template #_="{ item }">
        <td>
          <TButtonRemove @click="removeItem(item)" />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TMessage :content="item.order.customer.name" noTranslate />
        </td>
      </template>
      <template #description="{ item }">
        <td>
          <TMessageText
            :value="item.description"
            noTranslate
            editable
            @change="item.description = $event"
          />
        </td>
      </template>
      <template #purchase_cost="{ item }">
        <td>
          <div class="d-flex">
            <TMessageMoney
              :amount="item.order.purchase_cost_paid"
              :currency="item.order.purchase_cost_currency_id"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.order.purchase_cost"
              :currency="item.order.purchase_cost_currency_id"
              color="muted"
            />
          </div>
        </td>
      </template>
      <template #purchase_cost_to_pay="{ item }">
        <td>
          <TMessageMoney
            :amount="item.order.purchase_cost_to_pay"
            :currency="item.order.purchase_cost_currency_id"
            editable
            @change="item.order.purchase_cost_to_pay = $event"
            :color="getColorCostOfGoodsToPay(item.order)"
          />
        </td>
      </template>
      <template #result-purchase_cost_to_pay>
        <th>
          <div class="d-flex">
            <TMessage content="Total:" />
            <i>
              <TMessageMoney
                :amount="getTotalCostOfGoodsToPay()"
                :currency="currency_id"
                class="ml-1"
                :color="getColorTotal()"
              />
            </i>
          </div>
        </th>
      </template>
    </TTableSimple>
    <ModalSelectOrders
      :show.sync="showModalSelectOrder"
      @select="addOrders"
      type="purchase_cost"
      :customer_id="customer_id"
    />
  </div>
</template>

<script>
import ModalSelectOrders from "./ModalSelectOrdersToPay.vue";

export default {
  components: {
    ModalSelectOrders,
  },
  props: {
    currency_id: {
      type: String,
      required: false,
    },
    total_amount: {
      type: Number,
      default: 0,
    },
    customer_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      showModalSelectOrder: false,
      items: [],
      itemInput: {},
      itemFields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
        },
        {
          key: "customer",
          label: "Customer",
        },
        {
          key: "order_id",
          label: "Order",
        },
        {
          key: "description",
          label: "Description",
          _style: "min-width: 200px",
          placeholder: "Description",
        },
        {
          key: "purchase_cost",
          label: "Paid/Cost of goods",
        },
        {
          key: "purchase_cost_to_pay",
          label: "Cost of goods to pay",
          _style: "min-width: 350px; width: 350px",
        },
      ],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        this.$emit("update:items", items);
      }
    },
  },
  methods: {
    onInputItem(value) {
      this.itemInput = value;
    },
    removeItem(item) {
      const itemIdx = this.items.findIndex(
        (di) => di.order.id == item.order.id
      );
      this.items.splice(itemIdx, 1);
    },
    addOrders(orders) {
      const items = orders.map((order) => {
        return {
          order: {
            ...order,
            purchase_cost_to_pay: order.purchase_cost_outstanding,
          },
          order_id: order.id,
          description: "",
        };
      });
      this.items = this.lodash.uniqBy([...this.items, ...items], "order.id");
    },
    getTotalCostOfGoodsToPay() {
      return this.lodash.sumBy(
        this.items.map((i) => i.order),
        "purchase_cost_to_pay"
      );
    },
    getColorCostOfGoodsToPay(order) {
      return this.getColorAmount(
        order.purchase_cost_to_pay,
        order.purchase_cost_outstanding
      );
    },
    getColorTotal() {
      return this.getColorAmount(
        this.getTotalCostOfGoodsToPay(),
        this.total_amount
      );
    },
    getColorAmount(amount, amount_outstanding) {
      if (amount > amount_outstanding) {
        return "warning";
      }
      if (amount === amount_outstanding) {
        return "success";
      }
      return null;
    },
    clear() {
      this.items = [];
    },
  },
};
</script>
