<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage content="Debt payment customer" bold />
        <TSpinner :loading="creating" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{ disabled: creating }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol md="6">
              <SSelectCard
                label="Card"
                class="mb-3"
                :value.sync="input.card_id"
                @change="input.currency_id = $event.currency_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                placeholder="Ref code"
                class="mb-3"
                :value.sync="input.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <SSelectCustomer
                label="Customer"
                :value.sync="input.transactionable_id"
                class="mb-3"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol>
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol>
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :value.sync="input.currency_id"
                    :selectOptions="{ disabled: true }"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="12">
              <TInputMoney
                label="Total amount"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.amount"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
        <CCol>
          <SelectTypeDebtPayment :value.sync="type" />
          <TableInputServiceFee
            v-if="type === 'payment_service'"
            ref="table_input_service_fee"
            :total_amount="input.amount"
            :currency_id="input.currency_id"
            @update:items="items = $event"
            :customer_id="input.transactionable_id"
            :key="`table_input_service_fee-${input.transactionable_id}`"
          />
          <TableInputCostOfGoods
            v-if="type === 'payment_order'"
            ref="table_input_purchase_cost"
            :total_amount="input.amount"
            :currency_id="input.currency_id"
            @update:items="items = $event"
            :customer_id="input.transactionable_id"
            :key="`table_input_purchase_cost-${input.transactionable_id}`"
          />
          <TableInputLadingBills
            v-if="type === 'payment_service_lading_bill'"
            ref="table_input_lading_bills"
            :total_amount="input.amount"
            :currency_id="input.currency_id"
            @update:items="items = $event"
            :customer_id="input.transactionable_id"
            :key="`table_input_lading_bills-${input.transactionable_id}`"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import SelectTypeDebtPayment from "../../../components/SelectTypeDebtPayment.vue";
import TableInputServiceFee from "../../../components/TableInputServiceFee.vue";
import TableInputCostOfGoods from "../../../components/TableInputCostOfGoods.vue";
import TableInputLadingBills from "../../../components/TableInputLadingBills.vue";

export default {
  components: {
    SelectTypeDebtPayment,
    TableInputServiceFee,
    TableInputCostOfGoods,
    TableInputLadingBills,
  },
  data() {
    return {
      input: {},
      items: [],
      receipts: [],
      type: "payment_service",
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_deposit_with_cash.creating"
      ];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_deposit_with_cash.create",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        ...this.input,
        transactionable_type: "user",
      };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });

      let payments = [];
      const type = this.type == 'payment_service_lading_bill' ? 'payment_service' : this.type

      switch (this.type) {
        case "payment_service":
          this.items.forEach((item) => {
            // payment service fee
            if (item.order.service_fee_to_pay > 0) {
              payments.push({
                amount: item.order.service_fee_to_pay,
                receiptable_type: "order",
                receiptable_id: item.order.id,
              });
            }
            // payment shipping fee
            item.lading_bills.forEach((lading_bill) => {
              if (lading_bill.shipping_fee_to_pay > 0) {
                payments.push({
                  amount: lading_bill.shipping_fee_to_pay,
                  receiptable_type: "lading_bill",
                  receiptable_id: lading_bill.id,
                });
              }
            });
          });
          break;
        case "payment_order":
          this.items.forEach((item) => {
            // payment cost of goods
            if (item.order.purchase_cost_to_pay > 0) {
              payments.push({
                amount: item.order.purchase_cost_to_pay,
                receiptable_type: "order",
                receiptable_id: item.order.id,
              });
            }
          });
          break;

        case "payment_service_lading_bill":
          this.items.forEach((item) => {
            // payment service fee of lading bills
            if (item.lading_bill.freight_charge_to_pay > 0) {
              payments.push({
                amount: item.lading_bill.freight_charge_to_pay,
                receiptable_type: "lading_bill",
                receiptable_id: item.lading_bill.id,
              });
            }
          });
          break;
      }

      payments.forEach((payment, index) => {
        params[`${type}[${index}][amount]`] = payment.amount;
        params[`${type}[${index}][receipts][0][receiptable_type]`] =
          payment.receiptable_type;
        params[`${type}[${index}][receipts][0][receiptable_id]`] =
          payment.receiptable_id;
      });

      return params;
    },
    clearInput() {
      this.input = {};
      this.receipts = [];
      this.$refs.table_input_service_fee?.clear();
      this.$refs.table_input_purchase_cost?.clear();
      this.$refs.table_input_lading_bills?.clear();
    },
  },
};
</script>
