<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage content="Collect advance payment" bold />
        <TSpinner :loading="creating" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{ disabled: creating }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol md="6">
              <SSelectCard
                label="Card"
                class="mb-3"
                :value.sync="input.card_id"
                @change="input.currency_id = $event.currency_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                placeholder="Ref code"
                class="mb-3"
                :value.sync="input.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <SSelectCustomer
                label="Customer"
                :value.sync="input.transactionable_id"
                class="mb-3"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol>
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol>
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :value.sync="input.currency_id"
                    :selectOptions="{ disabled: true }"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="12">
              <TInputMoney
                label="Amount"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.amount"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      input: {},
      receipts: [],
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_deposit_with_cash.creating"
      ];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_deposit_with_cash.create",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = { ...this.input };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });
      return params;
    },
    clearInput() {
      this.input = {};
      this.receipts = [];
    },
  },
};
</script>
