<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage content="Other collect" bold />
        <TSpinner :loading="creating" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{
            disabled:
              creating || !input.credit_id || input.debit_id == input.credit_id,
          }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol>
              <SSelectReasonForArising
                label="Collect reason"
                class="mb-3"
                :value.sync="reasonIdSelected"
                :reasonList="reasonList"
                @change="reasonSelected = $event"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <SSelectCard
                label="Card"
                class="mb-3"
                :value.sync="input.card_id"
                @change="input.currency_id = $event.currency_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                class="mb-3"
                :value.sync="input.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <SCardSelectObjectTransaction
                v-if="groupObjectByReason.length"
                :value.sync="object"
                :typesPick="groupObjectByReason"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol>
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol>
                  <SSelectCurrency
                    label="Currency"
                    :selectOptions="{ disabled: true }"
                    :prepend="[]"
                    :value.sync="input.currency_id"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <TInputMoney
                label="Amount"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.amount"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
          <SCardSelectReferenceReceipt
            v-if="groupInputByReason.length"
            :receipt.sync="temp"
            :typesPick="
              groupInputByReason[0] == 'all' ? [] : groupInputByReason
            "
          />
          <hr />
          <CRow>
            <CCol md="6">
              <SSelectAccountGroup
                label="Debit"
                :group="['112']"
                class="mb-3"
                :value.sync="input.debit_id"
              />
            </CCol>
            <CCol md="6">
              <SSelectAccountGroup
                label="Credit"
                :group="groupCreditByReason"
                class="mb-3"
                :value.sync="input.credit_id"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      temp: {
        receiptable_id: "",
        receiptable_type: "",
      },
      object: {
        transactionable_type: "",
      },
      input: {
        debit_id: "112",
        credit_id: "",
      },
      receipts: [],
      reasonList: [
        {
          id: "collect-bank-interest",
          name: "Collect bank interest",
          credit: ["515"],
        },
        {
          id: "other",
          name: "Other",
          object: ["all"],
          credit: ["all"],
          reference: ["all"],
        },
      ],
      reasonIdSelected: "other",
      reasonSelected: null,
    };
  },
  computed: {
    creating() {
      return this.$store.getters["accounting.transactions.creating"];
    },
    groupCreditByReason() {
      return this.reasonSelected?.credit || [];
    },
    groupInputByReason() {
      return this.reasonSelected?.reference || [];
    },
    groupObjectByReason() {
      return this.reasonSelected?.object || [];
    },
  },
  watch: {
    "temp.receiptable_type": function () {
      this.temp.receiptable_id = "";
    },
    "object.transactionable_type": function () {
      this.object.transactionable_id = "";
    },
    reasonSelected: {
      deep: true,
      handler(value) {
        if (value?.credit?.length == 1) {
          if (value.credit[0] == "all") {
            this.input.credit_id = "";
            return;
          }
          this.input.credit_id = value.credit[0];
        }
      },
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch("accounting.transactions.create", this.getDataCreate())
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        type_id: "other-receivables",
        ...this.input,
        ...this.object,
      };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });

      if (this.temp.receiptable_type && this.temp.receiptable_id) {
        const nextIndexReceipt = this.receipts.length;
        params[`receipts[${nextIndexReceipt}][receiptable_type]`] =
          this.temp.receiptable_type;
        params[`receipts[${nextIndexReceipt}][receiptable_id]`] =
          this.temp.receiptable_id;
      }

      return this.lodash.pickBy(params, this.lodash.identity);
    },
    clearInput() {
      this.temp = {
        receiptable_id: "",
        receiptable_type: "",
      };
      this.object = {
        transactionable_type: "",
      };
      if (
        this.groupCreditByReason.length > 1 ||
        this.groupCreditByReason[0] == "all"
      ) {
        this.input = {
          credit_id: "",
          debit_id: "112",
        };
      }
      if (this.groupCreditByReason.length == 1) {
        this.input = {
          debit_id: "112",
          credit_id: this.groupCreditByReason[0],
        };
      }
      this.receipts = [];
    },
  },
};
</script>
