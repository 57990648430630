<template>
  <div>
    <TTableSimple
      :fields="itemFields"
      :items="items"
      @update:create="onInputItem"
    >
      <template #_-header>
        <TButton
          icon="cil-plus"
          size="sm"
          tooltip="Add wallets"
          variant="ghost"
          @click="showModalSelectWallet = true"
        />
      </template>
      <template #_="{ item }">
        <td>
          <TButtonRemove @click="removeItem(item)" noConfirm />
        </td>
      </template>
      <template #wallet="{ item }">
        <td>
          <SMessageWallet :data="item" />
        </td>
      </template>
      <template #description="{ item }">
        <td>
          <TMessageText
            :value="item.description"
            noTranslate
            editable
            @change="item.description = $event"
          />
        </td>
      </template>
      <template #amount="{ item }">
        <td>
          <TMessageMoney
            :amount="item.amount"
            :currency="item.currency_id"
            editable
            @change="item.amount = $event"
          />
        </td>
      </template>
      <template #result-amount>
        <th>
          <div class="d-flex">
            <TMessage content="Total:" />
            <i>
              <TMessageMoney
                :amount="getTotalDeposit()"
                :currency="currency_id"
                class="ml-1"
                :color="getColorTotal()"
              />
            </i>
          </div>
        </th>
      </template>
    </TTableSimple>
    <ModalSelectWallets
      :show.sync="showModalSelectWallet"
      @select="addWallets"
      :currency_id="currency_id"
    />
  </div>
</template>

<script>
import ModalSelectWallets from "./ModalSelectWallets.vue";

export default {
  components: {
    ModalSelectWallets,
  },
  props: {
    currency_id: {
      type: String,
      required: false,
    },
    total_amount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showModalSelectWallet: false,
      items: [],
      itemInput: {},
      itemFields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
          _style: "width: 40px",
        },
        {
          key: "wallet",
          label: "Wallet",
          _style: "min-width: 150px",
        },
        {
          key: "description",
          label: "Description",
          _style: "min-width: 150px",
          placeholder: "Description",
        },
        {
          key: "amount",
          label: "Amount",
          _style: "min-width: 150px",
        },
      ],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        this.$emit("update:items", items);
      },
    },
  },
  methods: {
    onInputItem(value) {
      this.itemInput = value;
    },
    removeItem(item) {
      const itemIdx = this.items.findIndex(
        (di) => di.walletable_id == item.walletable_id
      );
      this.items.splice(itemIdx, 1);
    },
    addWallets(wallets) {
      const items = wallets.map((wallet) => {
        return {
          ...wallet,
          amount: 0,
          description: "",
        };
      });
      this.items = this.lodash.uniqBy(
        [...this.items, ...items],
        "walletable_id"
      );
    },
    getTotalDeposit() {
      return this.lodash.sumBy(this.items, "amount");
    },
    getColorTotal() {
      return this.getColorAmount(this.getTotalDeposit(), this.total_amount);
    },
    getColorAmount(amount, amount_outstanding) {
      if (amount > amount_outstanding) {
        return "warning";
      }
      if (amount === amount_outstanding) {
        return "success";
      }
      return null;
    },
    clear() {
      this.items = [];
    },
  },
};
</script>
