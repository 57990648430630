<template>
  <TModal
    title="Select lading bill"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #actions>
      <div class="d-flex w-100">
        <TMessage content="Selected: ">
          <template #suffix>{{ ordersSelected.length }}</template>
        </TMessage>
        <TButton
          :options="{ disabled: lodash.isEmpty(ordersSelected) }"
          variant="outline"
          content="Select"
          icon="cil-check"
          class="ml-auto"
          @click="submitOrdersSelected"
        />
      </div>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="warehouse.lading_bills_receivable"
      resource=""
      @click-clear-filter="clearFilter"
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 py-1 ml-1 text-center my-auto" col="12">
          <TInputCheckbox
            :checked="lodash.findIndex(ordersSelected, ['id', item.id]) !== -1"
            @change="selectOrder(item)"
          />
        </CCol>
      </template>

      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>

      <template #customer="{ item }">
        <td>
          <TMessage
            v-if="item.customer"
            :content="item.customer.name"
            noTranslate
            class="text-nowrap"
          />
          <TMessageNotFound v-else />
        </td>
      </template>

      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate class="text-nowrap" />
        </td>
      </template>

      <template #freight_charge="{ item }">
        <td>
          <div class="d-flex">
            <TMessageMoney
              :amount="item.freight_charge_paid"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.freight_charge"
              :currency="item.currency_id"
            />
          </div>
        </td>
      </template>

      <template #freight_charge_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.freight_charge_outstanding"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #customer-filter v-if="!customer_id">
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    customer_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      filter: {},
      ordersSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      list: "warehouse.lading_bills_receivable.list",
    }),
    fields() {
      return [
        { key: "_", _style: "width: 40px" },
        {
          key: "customer",
          label: "Customer",
          _style: "width: 170px; min-width: 170px",
          _classes: "text-nowrap",
        },
        { key: "id", label: "Lading bill", _style: "width: 200px" },
        { key: "freight_charge", label: "Paid/Shipping fee" },
        {
          key: "freight_charge_outstanding",
          label: "Shipping fee outstanding",
          sorter: true,
        },
      ];
    },
  },
  watch: {
    show(show) {
      if (show) {
        this.filterChange();
      }
    },
  },
  methods: {
    submitOrdersSelected() {
      this.$emit("select", this.ordersSelected);
      this.$emit("update:show", false);
      this.ordersSelected = [];
    },
    selectOrder(order) {
      const index = this.ordersSelected.findIndex((x) => x.id === order.id);
      if (index < 0) {
        this.ordersSelected.push(order);
      } else this.ordersSelected.splice(index, 1);
    },
    filterChange() {
      let defaultFilter = {
        "filter[customer_id]": this.customer_id,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...defaultFilter,
        ...filterList,
      });
      this.$store.dispatch(
        "warehouse.lading_bills_receivable.apply-query",
        query
      );
    },
    clearFilter() {
      this.filter = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>
