<template>
  <TModal
    title="Select wallets"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #actions>
      <div class="d-flex w-100">
        <TMessage content="Selected: ">
          <template #suffix>{{ walletsSelected.length }}</template>
        </TMessage>
        <TButton
          :options="{ disabled: lodash.isEmpty(walletsSelected) }"
          variant="outline"
          content="Select"
          icon="cil-check"
          class="ml-auto"
          @click="submitOrdersSelected"
        />
      </div>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="accounting.wallets_users"
      @click-clear-filter="clearFilter"
      resource=""
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 py-1 ml-1 text-center my-auto" col="12">
          <TInputCheckbox
            :checked="lodash.findIndex(walletsSelected, ['id', item.id]) !== -1"
            @change="selectWallet(item)"
          />
        </CCol>
      </template>
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" />
        </td>
      </template>
      <template #wallet="{ item }">
        <td>
          <SMessageWallet :data="item" />
        </td>
      </template>
      <template #balance="{ item }">
        <td>
          <TMessageMoney :amount="item.balance" :currency="item.currency_id" />
        </td>
      </template>
      <template #amount_available_for_payment="{ item }">
        <td>
          <TMessageMoney
            :amount="item.amount_available_for_payment"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>
      <template #wallet-filter class="d-inline-flex">
        <div class="d-inline-flex w-100">
          <SSelectCustomer
            :value.sync="filter.walletable_id"
            @change="filterChange"
            noCustomLabel
            prependAll
            class="w-100"
          />
        </div>
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currency_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      filter: {},
      walletsSelected: [],
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "wallet",
          label: "Wallet",
          _style: "min-width: 250px",
          _classes: "text-wrap",
        },
        { key: "balance", label: "Balance", sorter: true },
        {
          key: "amount_available_for_payment",
          label: "Available payment",
          sorter: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      list: "accounting.wallets_users.list",
    }),
  },
  watch: {
    show(show) {
      if (show) {
        this.filterChange();
      }
    },
  },
  methods: {
    submitOrdersSelected() {
      this.$emit("select", this.walletsSelected);
      this.$emit("update:show", false);
      this.walletsSelected = [];
    },
    selectWallet(wallet) {
      const index = this.walletsSelected.findIndex((x) => x.id === wallet.id);
      if (index < 0) {
        this.walletsSelected.push(wallet);
      } else this.walletsSelected.splice(index, 1);
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy({
        ...filter,
        [`filter[currency_id]`]: this.currency_id,
      });
      this.$store.dispatch(
        "accounting.wallets_users.apply-query",
        filterFields
      );
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
