<template>
  <TModal
    title="Select orders"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div class="d-flex w-100">
        <div class="d-flex align-items-center">
          <TMessage content="Selected" bold />
          <span class="ml-2">{{ ordersSelected.length }}</span>

          <CLink class="ml-2 d-flex align-items-center" @click="onSelectAll">
            <CIcon name="cil-check" class="mr-1" />
            <TMessage content="Select all" bold />
          </CLink>
          <CLink
            class="ml-2 d-flex text-danger align-items-center"
            @click="ordersSelected = []"
            v-if="ordersSelected.length"
          >
            <CIcon name="cil-x-circle" class="mr-1" />
            <TMessage content="Deselect all" bold color="text-danger" />
          </CLink>
        </div>
        <TButton
          :options="{ disabled: lodash.isEmpty(ordersSelected) }"
          variant="outline"
          content="Select"
          icon="cil-check"
          class="ml-auto"
          @click="submitOrdersSelected"
        />
      </div>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="order.payment_orders"
      @click-clear-filter="clearFilter"
      resource=""
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 py-1 ml-1 text-center my-auto" col="12">
          <TInputCheckbox
            :checked="lodash.findIndex(ordersSelected, ['id', item.id]) !== -1"
            @change="selectOrder(item)"
          />
        </CCol>
      </template>
      <template #customer="{ item }">
        <td>
          <TMessage
            :content="item.customer.name"
            noTranslate
            class="text-nowrap"
          />
        </td>
      </template>
      <template #contract_id="{ item }">
        <td>
          <TMessage
            :content="item.contract_id"
            noTranslate
            class="text-nowrap"
          />
        </td>
      </template>
      <template #id="{ item }">
        <td>
          <TLink
            :content="item.id"
            :to="lodash.getReferenceLink('order', item.id)"
          />
        </td>
      </template>
      <template #shipping_fee_outstanding="{ item }">
        <td>
          <TMessageMoney
            v-for="(lading_bill, index) in item.lading_bills"
            :key="'shipping-fee-to-pay-' + index"
            :amount="lading_bill.freight_charge_outstanding"
            :currency="lading_bill.currency_id"
          />
        </td>
      </template>
      <template #service_fee_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.service_fee_outstanding"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>
      <template #purchase_cost_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.purchase_cost_outstanding"
            :currency="item.purchase_cost_currency_id"
          />
        </td>
      </template>
      <template #shipping_fee="{ item }">
        <td class="text-nowrap">
          <div
            v-for="(lading_bill, index) in item.lading_bills"
            :key="'shipping-fee-' + index"
            class="d-flex"
          >
            <TMessageMoney
              :amount="lading_bill.freight_charge_paid"
              :currency="lading_bill.currency_id"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="lading_bill.freight_charge"
              :currency="lading_bill.currency_id"
              color="muted"
            />
          </div>
        </td>
      </template>
      <template #service_fee="{ item }">
        <td>
          <div class="d-flex">
            <TMessageMoney
              :amount="item.service_fee_paid"
              :currency="item.service_fee_currency_id"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.service_fee"
              :currency="item.service_fee_currency_id"
              color="muted"
            />
          </div>
        </td>
      </template>
      <template #purchase_cost="{ item }">
        <td>
          <div class="d-flex">
            <TMessageMoney
              :amount="item.purchase_cost_paid"
              :currency="item.purchase_cost_currency_id"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.purchase_cost"
              :currency="item.purchase_cost_currency_id"
              color="muted"
            />
          </div>
        </td>
      </template>
      <template #lading_bills="{ item }">
        <td>
          <TMessage
            v-for="(lading_bill, index) in item.lading_bills"
            :key="'ldb-id' + index"
            :content="lading_bill.id"
          />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          placeholder="Order Id"
          :value.sync="filter.id"
          @update:value="filterChange"
        />
      </template>
      <template #lading_bills-filter>
        <TInputText
          placeholder="Bill of lading code"
          :value.sync="filter.lading_bill_id"
          @update:value="filterChange"
        />
      </template>
      <template #customer-filter v-if="!customer_id">
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #contract_id-filter>
        <SSelectContract
          :value.sync="filter.contract_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        return ["service_fee", "purchase_cost"].includes(type);
      },
    },
    customer_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      filter: {},
      ordersSelected: [],
    };
  },
  computed: {
    ...mapGetters({
      list: "order.payment_orders.list",
      paginate: "order.payment_orders.paginate",
    }),
    fields() {
      const fee_fields =
        this.type === "service_fee"
          ? [
              { key: "service_fee", label: "Paid/Service fee" },
              {
                key: "service_fee_outstanding",
                label: "Service fee outstanding",
                sorter: true,
              },
            ]
          : [
              { key: "purchase_cost", label: "Paid/Cost of goods" },
              {
                key: "purchase_cost_outstanding",
                label: "Cost of goods outstanding",
                sorter: true,
              },
            ];
      return [
        { key: "_", _style: "width: 40px" },
        {
          key: "customer",
          label: "Customer",
          _style: "width: 170px; min-width: 170px",
          _classes: "text-nowrap",
        },
        {
          key: "contract_id",
          label: "Contract",
          _style: "width: 170px; min-width: 170px",
          _classes: "text-nowrap",
        },
        {
          key: "id",
          label: "Order Id",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "lading_bills",
          label: "Lading bill",
          _style: "width: 130px; min-width: 130px",
        },
        ...fee_fields,
      ];
    },
  },
  watch: {
    show(show) {
      if (show) {
        this.filterChange();
      }
    },
  },
  methods: {
    submitOrdersSelected() {
      this.$emit("select", this.ordersSelected);
      this.$emit("update:show", false);
      this.ordersSelected = [];
    },
    selectOrder(order) {
      const index = this.ordersSelected.findIndex((x) => x.id === order.id);
      if (index < 0) {
        this.ordersSelected.push(order);
      } else this.ordersSelected.splice(index, 1);
    },
    onSelectAll() {
      this.list.forEach((order) => {
        const index = this.ordersSelected.findIndex((x) => x.id === order.id);
        if (index < 0) {
          this.selectOrder(order);
        }
      });
    },
    filterChange() {
      let defaultFilter = {
        "filter[customer_id]": this.customer_id,
        "filter_between[service_fee_outstanding]":
          this.type === "service_fee" ? "0,infinity" : "",
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...defaultFilter,
        ...filterList,
      });
      this.$store.dispatch("order.payment_orders.apply-query", query);
    },
    clearFilter() {
      this.filter = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>
