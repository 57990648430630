<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #collect-advance-payment>
        <CollectAdvancePayment />
      </template>
      <template #collect-advance-payment-customers>
        <CollectAdvancePaymentCustomers />
      </template>
      <template #debt-payment-customer>
        <DebtPaymentCustomer />
      </template>
      <template #debt-payment-customers>
        <DebtPaymentCustomers />
      </template>
      <template #other-collect>
        <OtherCollect />
      </template>
    </TTabs>
  </div>
</template>

<script>
import CollectAdvancePayment from "./CollectAdvancePayment.vue";
import CollectAdvancePaymentCustomers from "./CollectAdvancePaymentCustomers.vue";
import DebtPaymentCustomer from "./DebtPaymentCustomer.vue";
import DebtPaymentCustomers from "./DebtPaymentCustomers.vue";
import OtherCollect from "./OtherCollect.vue";

export default {
  components: {
    CollectAdvancePayment,
    CollectAdvancePaymentCustomers,
    DebtPaymentCustomer,
    DebtPaymentCustomers,
    OtherCollect
  },
  data() {
    return {
      tabs: [
        {
          key: "collect-advance-payment",
          name: "Collect advance payment",
          icon: "cisLibraryBooks",
        },
        {
          key: "collect-advance-payment-customers",
          name: "Collect advance payment (multipe customers)",
          icon: "cisLibraryBooks",
        },
        {
          key: "debt-payment-customer",
          name: "Debt payment customer",
          icon: "cisLibraryBooks",
        },
        {
          key: "debt-payment-customers",
          name: "Debt payment customers",
          icon: "cisLibraryBooks",
        },
        {
          key: "other-collect",
          name: "Other collect",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
