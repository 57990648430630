<template>
  <div>
    <TTableSimple
      :fields="itemFields"
      :items="items"
      @update:create="onInputItem"
    >
      <template #_-header>
        <TButton
          icon="cil-plus"
          size="sm"
          tooltip="Add order"
          variant="ghost"
          @click="showModalSelectOrder = true"
        />
      </template>
      <template #_="{ item }">
        <td>
          <TButtonRemove @click="removeItem(item)" />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TMessage :content="item.order.customer.name" noTranslate />
        </td>
      </template>
      <template #description="{ item }">
        <td>
          <TMessageText
            :value="item.description"
            noTranslate
            editable
            @change="item.description = $event"
          />
        </td>
      </template>
      <template #service_fee="{ item }">
        <td>
          <div class="d-flex">
            <TMessageMoney
              :amount="item.order.service_fee_paid"
              :currency="item.order.service_fee_currency_id"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.order.service_fee"
              :currency="item.order.service_fee_currency_id"
              color="muted"
            />
          </div>
        </td>
      </template>
      <template #service_fee_to_pay="{ item }">
        <td>
          <TMessageMoney
            :amount="item.order.service_fee_to_pay"
            :currency="item.order.service_fee_currency_id"
            editable
            @change="item.order.service_fee_to_pay = $event"
            :color="getColorServiceFeeToPay(item.order)"
          />
        </td>
      </template>
      <template #result-service_fee_to_pay>
        <th>
          <div class="d-flex">
            <TMessage content="Total:" />
            <i>
              <TMessageMoney
                :amount="getTotalServiceFeeToPay()"
                :currency="currency_id"
                class="ml-1"
                :color="getColorTotal()"
              />
            </i>
          </div>
        </th>
      </template>
    </TTableSimple>
    <ModalSelectOrders
      :show.sync="showModalSelectOrder"
      @select="addOrders"
      type="service_fee"
      :customer_id="customer_id"
    />
  </div>
</template>

<script>
import ModalSelectOrders from "./ModalSelectOrdersToPay.vue";

export default {
  components: {
    ModalSelectOrders,
  },
  props: {
    currency_id: {
      type: String,
      required: false,
    },
    total_amount: {
      type: Number,
      default: 0,
    },
    customer_id: {
      type: String,
      reuqired: false,
    },
  },
  data() {
    return {
      showModalSelectOrder: false,
      items: [],
      itemInput: {},
      itemFields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
        },
        {
          key: "customer",
          label: "Customer",
        },
        {
          key: "order_id",
          label: "Order",
        },
        {
          key: "description",
          label: "Description",
          _style: "min-width: 200px",
          placeholder: "Description",
        },
        {
          key: "service_fee",
          label: "Paid/Service fee",
        },
        {
          key: "service_fee_to_pay",
          label: "Service fee to pay",
          _style: "min-width: 300px; width: 300px",
        },
      ],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(items) {
        this.$emit("update:items", items);
      },
    },
  },
  methods: {
    onInputItem(value) {
      this.itemInput = value;
    },
    removeItem(item) {
      const itemIdx = this.items.findIndex(
        (di) => di.order.id == item.order.id
      );
      this.items.splice(itemIdx, 1);
    },
    addOrders(orders) {
      const items = orders.map((order) => {
        return {
          order: {
            ...order,
            service_fee_to_pay: order.service_fee_outstanding,
          },
          order_id: order.id,
          lading_bills: this.filterLadingBills(order.lading_bills ?? []),
          description: "",
        };
      });
      this.items = this.lodash.uniqBy([...this.items, ...items], "order.id");
    },
    filterLadingBills(lading_bills) {
      return lading_bills.map((lading_bill) => {
        return {
          ...lading_bill,
          shipping_fee_to_pay: lading_bill.freight_charge_outstanding,
        };
      });
    },
    getTotalServiceFeeToPay() {
      return this.lodash.sumBy(
        this.items.map((i) => i.order),
        "service_fee_to_pay"
      );
    },
    getColorServiceFeeToPay(order) {
      return this.getColorAmount(
        order.service_fee_to_pay,
        order.service_fee_outstanding
      );
    },
    getColorShippingFeeToPay(lading_bill) {
      return this.getColorAmount(
        lading_bill.shipping_fee_to_pay,
        lading_bill.freight_charge_outstanding
      );
    },
    getColorTotal() {
      return this.getColorAmount(
        this.getTotalServiceFeeToPay(),
        this.total_amount
      );
    },
    getColorAmount(amount, amount_outstanding) {
      if (amount > amount_outstanding) {
        return "warning";
      }
      if (amount === amount_outstanding) {
        return "success";
      }
      return null;
    },
    clear() {
      this.items = [];
    },
    ladingBillDuplicate(lading_bill) {
      return (
        this.items
          .flatMap((i) => i.lading_bills)
          .filter((l) => l.id === lading_bill.id).length > 1
      );
    },
  },
};
</script>
