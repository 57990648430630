<template>
  <TInputRadio
    label="Receivable for"
    :list="list"
    :value="value"
    @update:value="select"
    custom
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "payment_service",
    },
  },
  data() {
    return {
      list: [
        { value: "payment_service", label: this.$t("Debt collection for service") },
        { value: "payment_order", label: this.$t("Debt collection for goods") },
        { value: "payment_service_lading_bill", label: this.$t("Debt collection for service lading bills") },
      ],
    };
  },
  methods: {
    select(value) {
      this.$emit("update:value", value);
    },
  },
};
</script>
